import { SELECT_USER, LOAD_USER, AUTH_USER, AUTH_LOAD_USER } from "./action";
// import axios from 'axios';

const defaultState = {
  AuthUserLogin: null,
  AuthUserId: null,
  user: [], // временная переменна
  users: [], // все пользователи
};

export const userReducer = (state = defaultState, action) =>{
    switch (action.type) {
      case SELECT_USER:
        return state;
      case LOAD_USER:
        return {
          ...state,
          users: action.user
        };
      case AUTH_USER:
          localStorage.setItem('AuthUserLogin', action.data.login);
          localStorage.setItem('AuthUserId', action.data.userid);
          return {
            ...state,
            AuthUserLogin: action.data.login,
            AuthUserId: action.data.userid
          };
      case AUTH_LOAD_USER:
        if (localStorage.getItem('AuthUserLogin') === null || localStorage.getItem('AuthUserId') === null){
          localStorage.removeItem('AuthUserLogin');
          localStorage.removeItem('AuthUserId');
          return {
            ...state,
            AuthUserLogin: null,
            AuthUserId: null
          };
        }else{
          return {
            ...state,
            AuthUserLogin: localStorage.getItem('AuthUserLogin'),
            AuthUserId: localStorage.getItem('AuthUserId')
          };
        }
        // if (action.data===null){
        //   return {
        //     ...state,
        //     AuthUser: false
        //   };
        // }else{
          // return {
          //   ...state,
          //   AuthUserLogin: action.data.login,
          //   AuthUserId: action.data.userid
          // };
        // }
      // case SELECT_USER_ID:
      //   return {
      //     ...state,
      //     user: state.users.find(users => users.id === action.payload)
      //   };
      // return Object.assign({}, state, {
      //   user: [...state.user.filter(user => user.id == action.payload)]
      // });
      // case EDIT_USER_SCORE:
      //   const update = Object.assign({}, state, {
      //     user: Object.assign({}, state.user, {
      //       score: action.payload
      //     })
      //   });
      //   cookie.save("user", update.user);
      //   return update;
      // return Object.assign({}, state, {
      //   user: Object.assign({}, state.user, {
      //     score: action.payload,
      //   })
      // });
      // case DEL_USER:
      //   axios.delete("user/" + action.payload);
      //   return Object.assign({}, state, {
      //     user: [...state.user.filter(user => user.id !== action.payload)]
      //   });
      default:
        return state;
    }
};