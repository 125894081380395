import axios from 'axios';
import qs from 'qs';
import { notification } from 'antd';

export const LOAD_USER = "LOAD_USER";
export const SELECT_USER = "SELECT_USER";
export const SELECT_USER_ID = "SELECT_USER_ID";
export const UPDATE_USER = "UPDATE_USER";
export const EDIT_USER = "EDIT_USER";
export const DEL_USER = "DEL_USER";

export const AUTH_USER = "AUTH_USER";
export const AUTH_LOAD_USER = "AUTH_LOAD_USER";


axios.defaults.baseURL = "https://api.polldeltasert.ru/api/";
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
axios.defaults.withCredentials = true;



export const LoadUsers = user => ({
  type: LOAD_USER,
  user
});

export const AuthUser = (data) => ({
  type: AUTH_USER,
  data
});

export const LoadAuthUser = () => ({
  type: AUTH_LOAD_USER,
});


export const SelectUsers = users => ({
  type: SELECT_USER,
  user: users
});


export const SelectUserId = id => ({
  type: SELECT_USER_ID,
  id: id
});

export const UpdateUser = user => ({
  type: UPDATE_USER,
  user: user
});

export const EditUser = user => ({
  type: EDIT_USER,
  payload: user
});

export const DelUser = id => ({
  type: DEL_USER,
  payload: id
});



export const fetchUsers = () => {
  return (dispatch) => {
    return axios.get("user/")
      .then(response => {
        dispatch(LoadUsers(response.data))
      })
      .catch(error => {
        throw (error);
      });
  };
};

export const Auth = (login) => {
  return (dispatch) => {
    return axios.post("user/", qs.stringify(login) )
      .then(response => {
        dispatch(AuthUser(response.data));
        notification.success({
          message: `Авторизация`,
          description: 'Вы успешно вошли на сайт. Сейчас вас перекинет на другую страницу.',
        });
        setTimeout(function () {
          window.location.href = '/';
        }, 2 * 1000);
      })
      .catch(error => {
        notification.error({
          message: `Ошибка`,
          description: 'Ошибка авторизации',
        });
        // throw (error);
      });
  };
};

export const LoadAuth = () => {
  return (dispatch) => {
    dispatch(LoadAuthUser())
  };
};