import axios from 'axios';
import qs from 'qs';
axios.defaults.baseURL = "https://api.polldeltasert.ru/api/";
axios.defaults.headers.post["Content-Type"] =
	"application/x-www-form-urlencoded";
axios.defaults.withCredentials = true;


export const ADD_ANSWERS = "ADD_ANSWERS";
export const UPDATE_ANSWERS = "UPDATE_ANSWERS";
export const SELECT_ANSWERS = "SELECT_ANSWERS";
export const DELETE_ALL_ANSWERS = "DELETE_ALL_ANSWERS";
export const DELETE_ANSWERS = "DELETE_ANSWERS";

export const GET_ANSWERS = "GET_ANSWERS";



export const AddAnswers = answers => ({
	type: ADD_ANSWERS,
	answers
});

export const UpdateAnswers = answers => ({
	type: UPDATE_ANSWERS,
	answers
});

export const SelectAnswers = id => ({
	type: SELECT_ANSWERS,
	id
});

export const DeleteAnswers = id => ({
	type: DELETE_ANSWERS,
	id: id
});
export const DeleteAllAnswers = () => ({
	type: DELETE_ALL_ANSWERS
});

export const getAnswers = answers => ({
	type: GET_ANSWERS,
	answers
});

export const fetchAnswers = (param) => {
	return (dispatch) => {
		return axios.post("answers/", qs.stringify(param))
			.then(response => {
				dispatch(UpdateAnswers(response.data))
			})
			.catch(error => {
				throw (error);
			});
	};
};


export const getAnswerList = () => {
	return (dispatch) => {
		return axios.get("answers/")
			.then(response => {
				dispatch(getAnswers(response.data))
			})
			.catch(error => {
				throw (error);
			});
	};
};
