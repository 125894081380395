import axios from 'axios';
// import qs from 'qs';
// Загрузка данных с бд
export const LOAD_QUESTION_MYSQL = "LOAD_QUESTION_MYSQL";
axios.defaults.baseURL = "https://api.polldeltasert.ru/api/";
axios.defaults.headers.post["Content-Type"] =
	"application/x-www-form-urlencoded";
axios.defaults.withCredentials = true;




//Создать опросы
export const SET_TITLE_QUESTION = "SET_TITLE_QUESTION"; // установить заголовок
export const SELECT_NOT = "SELECT_NOT";
export const ADD_NOT = "ADD_NOT";
export const UPDATE_NOT = "UPDATE_NOT";
export const UPDATE_SELECTED_NOT = "UPDATE_SELECTED_NOT";
export const DELETE_NOT = "DELETE_NOT";
export const DELETE_ALL_NOT = "DELETE_ALL_NOT";

export const ADD_QUESTIONS_MYSQL = "ADD_QUESTIONS_MYSQL";
export const UPDATE_QUESTIONS_MYSQL = "UPDATE_QUESTIONS_MYSQL";
export const LOAD_QUESTION_LOCAL = "LOAD_QUESTION_LOCAL";


export const DELETE_QUESTIONS_ITEMS = "DELETE_QUESTIONS_ITEMS"; //Удалить 1 запись из закржунных уже бд
export const LOAD_QUESTIONS_ITEMS_LOCAL = "LOAD_QUESTIONS_ITEMS_LOCAL"; //когда нажали редактирвоать сюда загружаются данные с интернета


//Создать опросы
export const SelectQuestion = id => ({
	type: SELECT_NOT,
	id: id
});

export const AddQuestion = questions => ({
	type: ADD_NOT,
	questions: questions
});

export const UpdateQuestion = questions => ({
	type: UPDATE_NOT,
	questions: questions
});

export const UpdateQuestionSelected = questions => ({
	type: UPDATE_SELECTED_NOT,
	questions: questions
});

export const DeleteQuestion = id => ({
	type: DELETE_NOT,
	id: id
});
export const DelAllQuestion = q => ({
	type: DELETE_ALL_NOT,
	q: q
});

export const AddQuestionTitle = (author, title) => ({
	type: SET_TITLE_QUESTION,
	author,
	title
});
//Сохранить полность опрос который был создан
export const SaveAllQuestion = () => ({
	type: ADD_QUESTIONS_MYSQL
});

//Обновить полность опрос который был создан
export const UpdateAllQuestion = (questionId) => ({
	type: UPDATE_QUESTIONS_MYSQL,
	questionId
});


//Загрузить опросы (готовые с базы)
export const AddQuestion_Mysql = questions => ({
	type: LOAD_QUESTION_MYSQL,
	questions: questions
});

export const DeleteQuestion_Mysql = id => ({
	type: DELETE_QUESTIONS_ITEMS,
	id
});

export const AddQuestion_Local = () => ({
	type: LOAD_QUESTION_LOCAL
});

export const fetchQuestions = () => {
	return (dispatch) => {
		return axios.get("questions/")
			.then(response => {
				dispatch(AddQuestion_Mysql(response.data))
			})
			.catch(error => {
				throw (error);
			});
	};
};

export const LoadQuestionsId_Mysql = (id) => {
	return (dispatch) => {
		return axios.get("questions/" + id)
			.then(response => {
				dispatch(LoadQuestionsId_local(response.data['0']))
			})
			.catch(error => {
				throw (error);
			});
	};
};

export const LoadQuestionsId_local = data => ({
	type: LOAD_QUESTIONS_ITEMS_LOCAL,
	data
});