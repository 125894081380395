import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Loadable from "react-loadable";
import "antd/dist/antd.css";
import './scss/fontawesome/fontawesome.scss';
import './App.scss';
import axios from 'axios';
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducs from "./store/reducer";
import { fetchUsers, LoadAuth} from "./store/User/action";
import { fetchQuestions } from "./store/Question/action";



// const store = createStore(rootReducs);
const store = createStore(rootReducs, applyMiddleware(thunk));
store.dispatch(LoadAuth());
store.dispatch(fetchUsers());
store.dispatch(fetchQuestions());


axios.defaults.baseURL = "https://api.polldeltasert.ru/api/";
axios.defaults.headers.common["Authorization"] = "cookiename";
// axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
axios.defaults.withCredentials = true;



const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = Loadable({
  loader: () => import('./views/index/index'),
  loading
});

const Default404 = Loadable({
  loader: () => import('./views/index/error'),
  loading
});

const DefaultAnswer = Loadable({
  loader: () => import('./views/DefaultAnswer/index'),
  loading
});
const Login = Loadable({
  loader: () => import('./views/Login/Login'),
  loading
});

const Auth = localStorage.getItem('AuthUserId');

class App extends Component {
  render() {
    if (Auth === false || Auth === null){
      return (
        <Provider store={store}>
          <BrowserRouter>
            <Switch>
              <Route path="/answer/:formId/:hash" name="Questions from Deltasert.ru" exact component={DefaultAnswer} />
              <Route path="/" name="Главная" component={Login} />
            </Switch>
          </BrowserRouter>
        </Provider>
      );
    }else{
      return (
        <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route path="/answer/:formId/:hash" name="Questions from Deltasert.ru" exact  component={DefaultAnswer} />
              <Route path="/" name="Главная" component={DefaultLayout} />
            <Route name="Ошибка 404" component={Default404} />
          </Switch>
        </BrowserRouter>
        </Provider>
      );
    }
  }
}

export default App;
