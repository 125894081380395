import { ADD_ANSWERS, UPDATE_ANSWERS, DELETE_ALL_ANSWERS, DELETE_ANSWERS, SELECT_ANSWERS,
  GET_ANSWERS } from "./action";


const defaultState = {
  answersTitle: null,
  answers: [],
  answersList: [],
  answers_id: [],
};

export const AnswersReducer = (state = defaultState, action) => {
  // const { answersAuthor, answersTitle, answers } = state;
  switch (action.type) {
    case SELECT_ANSWERS:
      return {
        ...state,
        answers_id: state.answers.filter(answers => answers.id === action.id)
      }
    case ADD_ANSWERS:
      return {
        ...state,
        answers: state.answers.concat(action.answers)
      }
    case UPDATE_ANSWERS: //название опроса
      return {
        ...state,
        answers: action.answers
      }
    case DELETE_ANSWERS:
      return Object.assign({}, state, {
        answers: [...state.answers.filter(answers => answers.id !== action.id)]
      });
    case DELETE_ALL_ANSWERS:
      return Object.assign({}, state, {
        answersTitle: null,
        answers: [...state.answers.splice(0, 0)]
      });
    case GET_ANSWERS:
      return {
        ...state,
        answersList: action.answers
      }
    default:
      return state;
  }
};