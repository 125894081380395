import axios from 'axios';
import qs from 'qs';
import {
  SELECT_NOT, ADD_NOT, UPDATE_NOT, UPDATE_SELECTED_NOT, DELETE_NOT, DELETE_ALL_NOT,
  LOAD_QUESTION_MYSQL, ADD_QUESTIONS_MYSQL, UPDATE_QUESTIONS_MYSQL, LOAD_QUESTION_LOCAL, DELETE_QUESTIONS_ITEMS, LOAD_QUESTIONS_ITEMS_LOCAL,
  SET_TITLE_QUESTION,
} from "./action";


const defaultState = {
  questionsTitle: null,
  questionsAuthor: null,
  questions: [{ id: 0, type: 'pageheader', label: 'Добро пожаловать на опрос', value: 'Для начало ты можешь меня изменить для этого нужно нажать на значок с права от заголовка. После чего можешь добавлять новые поля в эту форму. Успехов тебе!', inputName: 'text', rules_required: 'false', rules_message: 'Поле обязательно', selects: [] },],
  questions_id: [],
  questionItems: [],
};

export const QuestionReducer = (state = defaultState, action) => {
  const { questionsAuthor, questionsTitle, questions } = state;
  switch (action.type) {
    case SELECT_NOT:
      return {
        ...state,
        questions_id: state.questions.filter(questions => questions.id === action.id)
      }
    case ADD_NOT:
      return {
        ...state,
        questions: state.questions.concat(action.questions)
      }
    case UPDATE_NOT:
      if (action.questions.type === 'select') {
        return Object.assign({}, state, {
          questions: state.questions.map(questions => questions.id === action.questions.id ?

            {
              ...questions,
              label: action.questions.label,
              placeholder: action.questions.placeholder,
              inputName: action.questions.inputName,
              rules_required: action.questions.rules_required,
              rules_message: action.questions.rules_message,
              multiple: action.questions.multiple,
              select: questions.select
            }
            : questions)
        });
      } else if (action.questions.type === 'checkbox') {
        return Object.assign({}, state, {
          questions: state.questions.map(questions => questions.id === action.questions.id ?

            {
              ...questions,
              label: action.questions.label,
              inputName: action.questions.inputName,
              rules_required: action.questions.rules_required,
              rules_message: action.questions.rules_message,
              select: questions.select
            }
            : questions)
        });
      } else if (action.questions.type === 'radio') {
        return Object.assign({}, state, {
          questions: state.questions.map(questions => questions.id === action.questions.id ?

            {
              ...questions,
              label: action.questions.label,
              inputName: action.questions.inputName,
              rules_required: action.questions.rules_required,
              rules_message: action.questions.rules_message,
              select: questions.select
            }
            : questions)
        });
      } else {
        return Object.assign({}, state, {
          questions: state.questions.map(questions => questions.id === action.questions.id ?
            action.questions
            : questions)
        });
      }
    case UPDATE_SELECTED_NOT:
      return Object.assign({}, state, {
        questions: state.questions.map(questions => questions.id === action.questions.id ?
          { ...questions, select: action.questions.names.map((name) => name) }
          : questions)
      });
    case DELETE_NOT:
      return Object.assign({}, state, {
        questions: [...state.questions.filter(questions => questions.id !== action.id)]
      });
    case DELETE_ALL_NOT:
      return Object.assign({}, state, {
        questionsTitle: null,
        questions: [...state.questions.splice(0, 0)]
      });
    case SET_TITLE_QUESTION: //название опроса
      return {
        ...state,
        questionsAuthor: action.author,
        questionsTitle: action.title
      }
    case ADD_QUESTIONS_MYSQL: // сохранить полностью опрос в mysql
      axios.post("questions/", qs.stringify({questionsAuthor, questionsTitle, questions}));
      return {...state,
        questionsTitle: null,
        questions: [{ id: 0, type: 'pageheader', label: 'Добро пожаловать на опрос', value: 'Для начало ты можешь меня изменить для этого нужно нажать на значок с права от заголовка. После чего можешь добавлять новые поля в эту форму. Успехов тебе!', inputName: 'text', rules_required: false, rules_message: 'Поле обязательно',},]
      };
    case UPDATE_QUESTIONS_MYSQL: // обновить полностью опрос в mysql
      const questionId = action.questionId;
      // const { questionsAuthor, questionsTitle, questions } = state;
      // axios.put("questions/" + questionId, qs.stringify({questionsAuthor, questionsTitle, questions}));
      axios.put("questions/" + questionId + "?" + qs.stringify({questionsAuthor, questionsTitle, questions}));
      return {...state,
        questionsTitle: null,
        questions: [{ id: 0, type: 'pageheader', label: 'Добро пожаловать на опрос', value: 'Для начало ты можешь меня изменить для этого нужно нажать на значок с права от заголовка. После чего можешь добавлять новые поля в эту форму. Успехов тебе!', inputName: 'text', rules_required: false, rules_message: 'Поле обязательно',},]
      };
    case LOAD_QUESTION_LOCAL: // загрузка всех опросов с базы (подругается где захочешь)
      const r = axios.get("questions/");
      return {
        ...state,
        questionItems: r.data
      }
    case LOAD_QUESTION_MYSQL: // загрузка всех опросов с базы
      return {
        ...state,
        questionItems: action.questions
      }
    case DELETE_QUESTIONS_ITEMS: //удалить 1 запись из сущ (загр из бд)
      axios.delete("questions/" + action.id);
      return Object.assign({}, state, {
        questionItems: [...state.questionItems.filter(questionItems => questionItems.id !== action.id)]
      });
    case LOAD_QUESTIONS_ITEMS_LOCAL:
      console.log(action.data);

      // return state;
      return {...state,
              questionsAuthor: action.data.author_id,
              questionsTitle: action.data.name,
              questions: action.data.forms
            };
    default:
      return state;
  }
};